import React, { type SyntheticEvent, type RefObject } from 'react';
import { styled as styled2 } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/standard-button';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics, FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { type ValidationError, emptyValidationError } from '../../../common/types';
import messages from './messages';

type Props = {
	hasRequiredFields?: boolean | null;
	summariesNo: number;
	validationError: ValidationError;
	onSubmitSingle: () => void;
	onSubmitMultiple: () => void;
	onClose: () => void;
	intl: IntlShape;
	innerRef: RefObject<HTMLDivElement>;
};

export const Dialog = ({
	hasRequiredFields = false,
	summariesNo = 0,
	validationError = emptyValidationError,
	onSubmitSingle = noop,
	onSubmitMultiple = noop,
	intl: { formatMessage },
	onClose = noop,
	innerRef,
}: Props) => {
	const maxMultiCreate = 1;
	const { summary: createMultipleButtonError, summaryConcat: createSingleButtonError } =
		validationError;

	const onClickSingleCreate = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		traceUFOPress('inline-card-create-submit', event.timeStamp);
		// @ts-expect-error Second argument should be of format "actionSubject action"
		fireUIAnalytics(analyticsEvent, 'clickedSingleCreate', 'singleCreateButton', {
			numIssues: summariesNo,
		});

		onSubmitSingle();
	};

	const onClickMultiCreate = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		traceUFOPress('inline-card-create-submit', event.timeStamp);
		// @ts-expect-error Second argument should be of format "actionSubject action"
		fireUIAnalytics(analyticsEvent, 'clickedMultiCreate', 'multiCreateButton', {
			numIssues: summariesNo,
		});

		onSubmitMultiple();
	};

	const createMultipleButton = (
		<Button
			testId="platform-inline-card-create.ui.form.multi-create-dialog.multi"
			appearance="primary"
			isDisabled={Boolean(createMultipleButtonError)}
			onClick={onClickMultiCreate}
		>
			{formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.createMultipleIssueTermRefresh
					: messages.createMultiple,
				{
					summariesNo: summariesNo > maxMultiCreate ? maxMultiCreate : summariesNo,
				},
			)}
		</Button>
	);

	const createSingleButton = (
		<ButtonWrapper>
			<Button
				testId="platform-inline-card-create.ui.form.multi-create-dialog.single"
				isDisabled={Boolean(createSingleButtonError)}
				onClick={onClickSingleCreate}
			>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.createSingleIssueTermRefresh
						: messages.createSingle,
				)}
			</Button>
		</ButtonWrapper>
	);

	let dialogBodyContent;
	if (summariesNo >= maxMultiCreate) {
		dialogBodyContent = formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.tooManyIssuesBodyIssueTermRefresh
				: messages.tooManyIssuesBody,
			{ maxNoSummaries: maxMultiCreate },
		);
	} else {
		dialogBodyContent = formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.bodyIssueTermRefresh
				: messages.body,
			{ summariesNo },
		);
	}

	const dialogContainer = (
		<DialogContainer
			data-testid="platform-inline-card-create.ui.form.multi-create-dialog.container"
			ref={innerRef}
		>
			<Heading size="xsmall">{formatMessage(messages.header)}</Heading>
			<DialogBody>{dialogBodyContent}</DialogBody>
			<ButtonGroup>
				{createMultipleButtonError ? (
					<Tooltip content={createMultipleButtonError}>{createMultipleButton}</Tooltip>
				) : (
					createMultipleButton
				)}
				{createSingleButtonError ? (
					<Tooltip content={createSingleButtonError}>{createSingleButton}</Tooltip>
				) : (
					createSingleButton
				)}
			</ButtonGroup>
			<FireUiAnalytics eventName="multiCreateDialog viewed" />
		</DialogContainer>
	);

	const dialogContainerWithRequiredFields = (
		<DialogContainer
			data-testid="platform-inline-card-create.ui.form.multi-create-dialog.container"
			ref={innerRef}
		>
			<Heading size="xsmall">
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.multiCreateDisabledHeaderIssueTermRefresh
						: messages.multiCreateDisabledHeader,
				)}
			</Heading>
			<DialogBody>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.multiCreateDisabledBodyIssueTermRefresh
						: messages.multiCreateDisabledBody,
				)}
			</DialogBody>
			<ButtonGroup>
				<Button
					testId="platform-inline-card-create.ui.form.multi-create-dialog.cancel"
					isDisabled={Boolean(createSingleButtonError)}
					onClick={onClose}
				>
					{formatMessage(messages.cancelButton)}
				</Button>
				{createSingleButtonError ? (
					<Tooltip content={createSingleButtonError}>{createSingleButton}</Tooltip>
				) : (
					<ButtonWrapper>
						<Button
							testId="platform-inline-card-create.ui.form.multi-create-dialog.single"
							isDisabled={Boolean(createSingleButtonError)}
							onClick={onClickSingleCreate}
							appearance="primary"
						>
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.createSingleIssueTermRefresh
									: messages.createSingle,
							)}
						</Button>
					</ButtonWrapper>
				)}
			</ButtonGroup>
			<FireUiAnalytics eventName="multiCreateDialog viewed" />
		</DialogContainer>
	);

	return hasRequiredFields === true ? dialogContainerWithRequiredFields : dialogContainer;
};

export default injectIntl(Dialog);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContainer = styled2.div({
	maxWidth: '280px',
	padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogBody = styled2.div({
	margin: `${token('space.150', '12px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled2.div({
	marginLeft: token('space.100', '8px'),
});
