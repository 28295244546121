import React, { type ReactNode, useEffect, useRef, useState } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { WarningFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { getIssueTypes } from '../../rest/software/software-api-urls';
import flagMessages from './messages';
import type { Props, IssueTypeResponse } from './types';

const fetchIssueTypes = (
	contextPath: string,
	fetchOptions: { [method: string]: string },
	missingIssueType: string,
): Promise<IssueTypeResponse> =>
	fetchJson(getIssueTypes(contextPath, missingIssueType), fetchOptions);

const fetchOptions: { [method: string]: string } = {
	method: 'GET',
};

const MissingIssueTypeFlag = ({
	id,
	onDismissed,
	contextPath,
	canEditBoard,
	wantedUrl,
	missingIssueType,
}: Props) => {
	const flagShown = useRef(false);
	const [issueType, setIssueType] = useState({
		name: '',
		isName: false,
	});
	const { formatMessage } = useIntl();
	const missingIssueTypeDescriptionMessage = expVal(
		'issue-terminology-refresh-m2-replace',
		'isEnabled',
		false,
	)
		? flagMessages.missingIssueTypeDescriptionIssueTermRefresh
		: flagMessages.missingIssueTypeDescription;

	const defaultMissingIssueTypeDescriptionMessage = expVal(
		'issue-terminology-refresh-m2-replace',
		'isEnabled',
		false,
	)
		? flagMessages.defaultMissingIssueTypeDescriptionIssueTermRefresh
		: flagMessages.defaultMissingIssueTypeDescription;

	useEffect(() => {
		fetchIssueTypes(contextPath, fetchOptions, missingIssueType)
			.then(({ name }: IssueTypeResponse) => {
				setIssueType({
					name,
					isName: true,
				});
			})
			.catch(() => {
				setIssueType({
					name: missingIssueType,
					isName: false,
				});
			});
	}, [missingIssueType, contextPath]);

	if (flagShown.current || !issueType.name) {
		return null;
	}

	if (!flagShown.current) flagShown.current = true;

	return (
		<WarningFlag
			id={id}
			title={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? flagMessages.missingIssueTypeTitleIssueTermRefresh
					: flagMessages.missingIssueTypeTitle,
			)}
			description={formatMessage(
				issueType.isName
					? missingIssueTypeDescriptionMessage
					: defaultMissingIssueTypeDescriptionMessage,
				{
					missingIssueTypeName: issueType.name,
					b: (chunks: ReactNode) => <b>{chunks}</b>,
				},
			)}
			actions={
				canEditBoard
					? [
							{
								content: formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? flagMessages.adminIssueTypeActionLinkIssueTermRefresh
										: flagMessages.adminIssueTypeActionLink,
								),
								href: wantedUrl,
								target: '_blank',
								onClick: () => onDismissed(id),
							},
						]
					: []
			}
		/>
	);
};

export default MissingIssueTypeFlag;
