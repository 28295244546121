import { expVal } from '@atlassian/jira-feature-experiments';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import messages from './messages';

export type SwimlaneMode = {
	id: SwimlaneModeId;
	message: MessageDescriptor;
	values?: {
		[key: string]: string;
	};
};

export const NO_SWIMLANE = {
	id: 'NO_SWIMLANE',
	message: messages.none,
} as const;

export const SWIMLANE_BY_ASSIGNEE = {
	id: 'SWIMLANE_BY_ASSIGNEE',
	message: messages.assignee,
} as const;

const selectAssigneeunassignedfirstMessage = functionWithCondition(
	() => expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false),
	() => messages.assigneeunassignedfirstIssueTermRefresh,
	() => messages.assigneeunassignedfirst,
);
/**
 * It is used by CMP UIF only
 * Swimlanes are group by assignee with the unassigned swimlane rendering on the top
 */
export const SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST = {
	id: 'SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST',
	message: selectAssigneeunassignedfirstMessage(),
} as const;

export const SWIMLANE_BY_PARENT_ISSUE = {
	id: 'SWIMLANE_BY_PARENT_ISSUE',
	message: messages.epic,
} as const;

export const SWIMLANE_BY_SUBTASK = {
	id: 'SWIMLANE_BY_SUBTASK',
	message: messages.subtask,
} as const;

export const SWIMLANE_BY_JQL = {
	id: 'SWIMLANE_BY_JQL',
	message: messages.jql,
} as const;

export const SWIMLANE_BY_PROJECT = {
	id: 'SWIMLANE_BY_PROJECT',
	message: messages.project,
} as const;

export const SWIMLANE_BY_TEAM = {
	id: 'SWIMLANE_BY_TEAM',
} as const;

export const SWIMLANE_BY_REQUEST_TYPE = {
	id: 'SWIMLANE_BY_REQUEST_TYPE',
	message: messages.requestType,
} as const;

export const defaultSwimlanes = [NO_SWIMLANE, SWIMLANE_BY_ASSIGNEE];

export const SWIMLANE_CHILDLESS = '$childless$';
export const SWIMLANE_PARENTLESS = '$parentless$';
export const SWIMLANE_UNASSIGNED = '$unassigned$';
