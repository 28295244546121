import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueLinksTooltipLabel: {
		id: 'issue-links-stats.issue-links-stats-button.issue-links-tooltip-label',
		defaultMessage: '{issueLinkCount} linked {issueLinkCount, plural, one {issue} other {issues}}',
		description: 'Tooltip for linked issues stats',
	},
	genericMaxIssueLinksStatsTooltipLabel: {
		id: 'issue-links-stats.issue-links-stats-button.generic-max-issue-links-stats-tooltip-label',
		defaultMessage: '{maxIssueLinksStatsCount}+ linked issues',
		description: 'Tooltip for linked issues stats',
	},
	issueLinksTooltipLabelIssueTermRefresh: {
		id: 'issue-links-stats.issue-links-stats-button.issue-links-tooltip-label-issue-term-refresh',
		defaultMessage: '{issueLinkCount} linked {issueLinkCount, plural, one {issue} other {issues}}',
		description: 'Tooltip for linked issues stats',
	},
	genericMaxIssueLinksStatsTooltipLabelIssueTermRefresh: {
		id: 'issue-links-stats.issue-links-stats-button.generic-max-issue-links-stats-tooltip-label-issue-term-refresh',
		defaultMessage: '{maxIssueLinksStatsCount}+ linked issues',
		description: 'Tooltip for linked issues stats',
	},
});
