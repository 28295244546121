import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ariaLabel: {
		id: 'inline-card-create.form.type-select.aria-label',
		defaultMessage:
			"The current issue type is {value}. Press the 'Space' key to change the issue type.",
		description: 'An aria-label describing what the select element allows to change.',
	},
	invalid: {
		id: 'inline-card-create.form.type-select.invalid',
		defaultMessage: 'invalid',
		description: 'A label that represents an invalid value for an issue type',
	},
	// Delete this message when cleaning up visual-refresh FG
	manageIssueTypes: {
		id: 'inline-card-create.form.type-select.manage-issue-types',
		defaultMessage: 'Manage issue types',
		description:
			'Dropdown menu item. Clicking this will take the user to a page where they can manage the the project issue types.',
	},
	manageTypes: {
		id: 'inline-card-create.form.type-select.manage-types',
		defaultMessage: 'Manage types',
		description:
			'Dropdown menu item. Clicking this will take the user to a page where they can manage the project issue types.',
	},
	addIssueType: {
		id: 'inline-card-create.form.type-select.add-issue-type',
		defaultMessage: 'Add issue type',
		description:
			'Dropdown menu item. Clicking this will open a modal where they can add or create new issue types.',
	},
	ariaLabelIssueTermRefresh: {
		id: 'inline-card-create.form.type-select.aria-label-issue-term-refresh',
		defaultMessage:
			"The current issue type is {value}. Press the 'Space' key to change the issue type.",
		description: 'An aria-label describing what the select element allows to change.',
	},
	manageIssueTypesIssueTermRefresh: {
		id: 'inline-card-create.form.type-select.manage-issue-types-issue-term-refresh',
		defaultMessage: 'Manage issue types',
		description:
			'Dropdown menu item. Clicking this will take the user to a page where they can manage the the project issue types.',
	},
	addIssueTypeIssueTermRefresh: {
		id: 'inline-card-create.form.type-select.add-issue-type-issue-term-refresh',
		defaultMessage: 'Add issue type',
		description:
			'Dropdown menu item. Clicking this will open a modal where they can add or create new issue types.',
	},
});
