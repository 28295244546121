import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	appliedFiltersTitleNew: {
		id: 'inline-card-create.form.inline-messages.applied-filters-title-new',
		defaultMessage: 'Filters applied',
		description:
			'An inline message title to remind the user that the issue will be created with filters applied.',
	},
	appliedFiltersDescription: {
		id: 'inline-card-create.form.inline-messages.applied-filters-description',
		defaultMessage: "We'll update this issue’s fields to match your current filters.",
		description:
			'An inline message description to remind the user that the issue will be created with filters applied. The {appliedFilters} is the number of filters that will be applied on the newly created issue.',
	},
	willAffectSprintScopeTitle: {
		id: 'inline-card-create.form.inline-messages.will-affect-sprint-scope-title',
		defaultMessage: 'Sprint scope will be affected',
		description:
			'An inline message title to remind the user that creating an issue will affect the sprint scope.',
	},
	willAffectSprintScopeDescription: {
		id: 'inline-card-create.form.inline-messages.will-affect-sprint-scope-description',
		defaultMessage: "Creating an issue in an active sprint will affect it's scope.",
		description:
			'An inline message description to remind the user that creating an issue will affect the sprint scope.',
	},
	validationErrorTitle: {
		id: 'inline-card-create.form.inline-messages.validation-error-title',
		defaultMessage:
			'{validationErrorsNo} {validationErrorsNo, plural, one {error} other {errors}} found',
		description:
			'An inline message title to remind the user that errors need to be fixed before issues can be created. The {validationErrorsNo} is the number of errors that need to be fixed before creating issues.',
	},
	appliedFiltersDescriptionIssueTermRefresh: {
		id: 'inline-card-create.form.inline-messages.applied-filters-description-issue-term-refresh',
		defaultMessage: "We'll update this issue’s fields to match your current filters.",
		description:
			'An inline message description to remind the user that the issue will be created with filters applied. The {appliedFilters} is the number of filters that will be applied on the newly created issue.',
	},
	willAffectSprintScopeDescriptionIssueTermRefresh: {
		id: 'inline-card-create.form.inline-messages.will-affect-sprint-scope-description-issue-term-refresh',
		defaultMessage: "Creating an issue in an active sprint will affect it's scope.",
		description:
			'An inline message description to remind the user that creating an issue will affect the sprint scope.',
	},
});
