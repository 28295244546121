import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	header: {
		id: 'software-board-size-limit-error-empty-state.size-limit-error-no-cta.header',
		defaultMessage: 'We couldn’t load your board',
		description: 'Message shown when a board would fail to load due to having 5000+ issues.',
	},
	description: {
		id: 'software-board-size-limit-error-empty-state.size-limit-error-no-cta.description',
		defaultMessage:
			'Boards only works in project with less than 5,000 issues. We’re working on this and will let you know when it has changed.',
		description: 'Description of the 5000+ issues board size limit error.',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-size-limit-error-empty-state.size-limit-error-no-cta.description-issue-term-refresh',
		defaultMessage:
			'Boards only works in project with less than 5,000 issues. We’re working on this and will let you know when it has changed.',
		description: 'Description of the 5000+ issues board size limit error.',
	},
});

export default messages;
