import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

interface Props {
	days: number;
}

export function CardDaysInColumn({ days }: Props) {
	let dots = <></>;
	if (days >= 20) {
		dots = (
			<>
				<Circle color={getColor('danger')} />
				<Circle color={getColor('danger')} />
				<Circle color={getColor('danger')} />
				<Circle color={getColor('danger')} />
			</>
		);
	} else if (days >= 12) {
		dots = (
			<>
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('danger')} />
				<Circle color={getColor('danger')} />
				<Circle color={getColor('danger')} />
			</>
		);
	} else if (days >= 8) {
		dots = (
			<>
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('danger')} />
				<Circle color={getColor('danger')} />
			</>
		);
	} else if (days >= 5) {
		dots = (
			<>
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('danger')} />
			</>
		);
	} else if (days >= 3) {
		dots = (
			<>
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('warning')} />
			</>
		);
	} else if (days >= 2) {
		dots = (
			<>
				<Circle color={getColor('subtle')} />
				<Circle color={getColor('subtleDark')} />
			</>
		);
	} else if (days >= 1) {
		dots = <Circle color={getColor('subtle')} />;
	}

	const { formatMessage } = useIntl();
	const content = formatMessage(messages.daysInColumn, { days: Math.floor(days) });

	return days < 1 ? null : (
		<Tooltip content={content}>
			<DaysInColumnContainer
				{...(fg('tooltip_not_accessible') && {
					'aria-label': content,
					tabIndex: 0,
				})}
			>
				{dots}
			</DaysInColumnContainer>
		</Tooltip>
	);
}

type Mode = 'danger' | 'warning' | 'subtle' | 'subtleDark';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Circle = styled.div<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => props.color,
	width: '6px',
	height: '6px',
	borderRadius: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DaysInColumnContainer = styled.div({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: token('space.025', '2px'),
});

const getColor = (mode: Mode): string => {
	if (mode === 'danger') {
		return token('color.icon.danger', '#d04437');
	}

	if (mode === 'warning') {
		return token('color.icon.warning', '#f8c15b');
	}

	if (mode === 'subtleDark') {
		return token('color.background.accent.gray.subtle', 'rgb(133, 144, 162)');
	}

	return token('color.icon.disabled', 'rgba(9, 30, 66, 0.31)');
};
