/** @jsx jsx */
import React, { useState, useMemo, useCallback, type ChangeEvent } from 'react';
import { css, jsx } from '@compiled/react';
import flatten from 'lodash/flatten';
import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import AddIcon from '@atlaskit/icon/core/add';
import AddIconOld from '@atlaskit/icon/glyph/add';
import { Box, Stack, xcss, Inline } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLinkedModalEntrypointActions } from '@atlassian/jira-issue-links-common/src/controllers/issue-links-modal-entrypoint-store/index.tsx';
import type { IssueLink } from '@atlassian/jira-issue-links-common/src/types.tsx';
import { IssueLinksDetailsGroup } from '@atlassian/jira-issue-links-details/src/ui/index.tsx';
import type { NewIssueLinksType } from '@atlassian/jira-issue-view-base/src/content/issue-links/add/types';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueLink as BoardIssueLink } from '@atlassian/jira-software-board-common';
import {
	useIssueIdsToShowDependencies,
	useViewSettingsActions,
} from '@atlassian/jira-software-view-settings/src/controllers';
import { wrapActionWithPromise } from '../../../../../../../common/utils/increment-planning';
import {
	useBoardSelector,
	useBoardActionCreator,
	useBoardDispatch,
} from '../../../../../../../state';
import {
	issueLinksRemoveRequest,
	issueLinkCreateRequest,
} from '../../../../../../../state/actions/issue/issue-link';
import { isIncrementPlanningReadOnly } from '../../../../../../../state/selectors/board/board-permissions-selectors';
import {
	getIssueLinksForDependenciesFlyout,
	getIssueLinksForDependenciesModal,
} from '../../../../../../../state/selectors/issue-link/issue-link-selectors';
import {
	getIssueById,
	getIssueTypeById,
} from '../../../../../../../state/selectors/issue/issue-selectors';
import {
	getIssues,
	rapidViewIdSelector,
} from '../../../../../../../state/selectors/software/software-selectors';
import { INWARD_LINK_DIRECTION } from '../../../../../../modals/link-issue-modal/constants';
import type { IssueLinkType } from '../../../../../../modals/link-issue-modal/types';
import messages from './messages';
import type { Props } from './types';

const IssueLinksDetailsGroupWrapper = (props: Props) => {
	const { formatMessage } = useIntl();
	const selectedIssueIdsForShowDependencyLines = useIssueIdsToShowDependencies();
	const { setIssueIdsToShowDependencies } = useViewSettingsActions();
	const boardId = Number(useBoardSelector(rapidViewIdSelector));
	const [isShowLinesChecked, setIsShowLinesChecked] = useState(
		selectedIssueIdsForShowDependencyLines.includes(`${props.issueId}`),
	);
	const issue = useBoardSelector((state) => getIssueById(state, props.issueId));
	const issueType = useBoardSelector((state) => getIssueTypeById(state, issue.typeId));
	const issues = useBoardSelector((state) => getIssues(state));
	const issuesArray = useMemo(() => Object.values(issues), [issues]);
	const {
		internal: issueLinks,
		external,
	}: { internal: { [relationName: string]: IssueLink[] }; external: number } = useBoardSelector(
		(state) => getIssueLinksForDependenciesFlyout(state)(props.issueId),
	);
	const issueLinkAndDirectionMap = useBoardSelector((state) =>
		getIssueLinksForDependenciesModal(state)(props.issueId),
	);
	const [maxIssuesToDisplay, setMaxIssuesToDisplay] = useState<number | undefined>(undefined);
	const allInternalLinks = flatten(Object.values(issueLinks));

	const isIPBoardReadOnly = useBoardSelector(isIncrementPlanningReadOnly);

	const { setIssueLinkModalEntryPointSubject } = useIssueLinkedModalEntrypointActions();
	const dispatch = useBoardDispatch();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onUnlinkIssueAction = useBoardActionCreator((boardIssueLink: BoardIssueLink) =>
		issueLinksRemoveRequest({
			issueId: props.issueId,
			issueLink: boardIssueLink,
		}),
	);

	const onUnlinkIssue = useCallback(
		async (issueLink: IssueLink) => {
			if (issueLink.issueLinkId) {
				const boardIssueLink = issue.issueLinks
					? issue.issueLinks.find((link) => link.id === issueLink.issueLinkId)
					: undefined;
				if (boardIssueLink) {
					fireUIAnalytics(
						createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
						'unlinkIssue',
					);

					return onUnlinkIssueAction(boardIssueLink);
				}
			}
		},
		[onUnlinkIssueAction, issue.issueLinks, createAnalyticsEvent],
	);

	const onLinkIssueRequest = useCallback(
		async (
			issueId: string | number,
			newIssueLinks: NewIssueLinksType[],
			issueLinkType: IssueLinkType,
		) => {
			const isInwards = issueLinkType.direction === INWARD_LINK_DIRECTION;

			return Promise.all(
				newIssueLinks.map(({ value }) =>
					wrapActionWithPromise(
						dispatch,
						issueLinkCreateRequest({
							sourceItemKey: `${isInwards ? value : issueId}`,
							targetItemKey: `${isInwards ? issueId : value}`,
							type: Number(issueLinkType.id),
						}),
					),
				),
			);
		},
		[dispatch],
	);

	const renderExternalLinksMessage = () => (
		<Stack space="space.050" xcss={[allInternalLinks.length > 0 && externalIssueLinksStyles]}>
			<Box xcss={titleStyles}>{formatMessage(messages.externalIssueLinksTitle)}</Box>
			<Box paddingBlockEnd="space.075">
				{formatMessage(messages.externalIssueLinksDescription, {
					externalIssueLinksCount: external,
				})}
			</Box>
		</Stack>
	);

	const handleShowLinesToggleChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(event: ChangeEvent<HTMLInputElement>, analyticsEvent: UIAnalyticsEvent) => {
			const isChecked = event.target.checked;
			setIsShowLinesChecked(isChecked);
			if (isChecked) {
				const newIds = [...selectedIssueIdsForShowDependencyLines, props.issueId];
				setIssueIdsToShowDependencies(boardId, Array.from(new Set(newIds)));
			} else {
				setIssueIdsToShowDependencies(
					boardId,
					selectedIssueIdsForShowDependencyLines.filter((id) => `${id}` !== `${props.issueId}`),
				);
			}
			// will review the analytics in the https://hello.jira.atlassian.cloud/browse/JPO-28989
			// fireUIAnalytics(analyticsEvent, 'toggleDependencyLines', { isChecked });
		},
		[setIssueIdsToShowDependencies, boardId, selectedIssueIdsForShowDependencyLines, props.issueId],
	);

	const renderToggleDependencyLinesAction = () => {
		if (!fg('dependency_visualisation_program_board_fe_and_be')) {
			return undefined;
		}

		return (
			<Inline alignBlock="center">
				<label htmlFor="toggle-show-lines" css={labelStyle}>
					{formatMessage(messages.showLines)}
				</label>
				<Toggle
					id="toggle-show-lines"
					isChecked={isShowLinesChecked}
					onChange={handleShowLinesToggleChange}
				/>
			</Inline>
		);
	};

	return (
		<Box
			padding="space.200"
			xcss={fg('dependency_visualisation_program_board_fe_and_be') && flyoutWrapper}
		>
			<IssueLinksDetailsGroup
				unlinkIssueButtonTooltipText={formatMessage(messages.removeDependency)}
				title={formatMessage(messages.title)}
				onUnlinkIssue={onUnlinkIssue}
				showBlockedTime={false}
				maxIssuesToDisplay={maxIssuesToDisplay}
				exceedMaxSizeLinkOptions={{
					text: formatMessage(messages.viewAllDependencies),
					onClick: () => {
						setMaxIssuesToDisplay(Infinity);
					},
				}}
				leftFooterLinkOptions={
					!isIPBoardReadOnly
						? {
								text: formatMessage(messages.addDependency),
								icon: fg('enghealth-12479-jsw-board-visual-refresh') ? (
									<AddIcon
										label={formatMessage(messages.unlinkIssues)}
										LEGACY_fallbackIcon={AddIconOld}
										color={token('color.icon')}
									/>
								) : (
									<AddIconOld label={formatMessage(messages.unlinkIssues)} />
								),
								onClick: () => {
									setIssueLinkModalEntryPointSubject({
										issueData: {
											issueId: String(issue.id),
											issueKey: issue.key,
											issueSummary: issue.summary,
											issueType,
										},
										modalContent: {
											title: formatMessage(messages.addDependencyKey),
											showDescription: false,
											showCreateIssueLinkButton: false,
											showFeedbackCollector: false,
											localIssuesOnly: true,
											confirmLabel: formatMessage(messages.addDependencyCta),
											issues: issuesArray,
											onLinkIssueRequest,
											issueLinks: issueLinkAndDirectionMap,
										},
									});
								},
							}
						: undefined
				}
				issueLinkGroups={issueLinks}
				showFeedbackCollector={false}
				{...(external > 0 && {
					renderExternalLinksMessage,
				})}
				hideTitleWhenEmpty
				canUserLinkIssue={!isIPBoardReadOnly}
				rightHeaderActions={renderToggleDependencyLinesAction()}
			/>
		</Box>
	);
};

export default IssueLinksDetailsGroupWrapper;

const externalIssueLinksStyles = xcss({
	paddingBlockStart: 'space.100',
});

const titleStyles = xcss({
	textTransform: 'uppercase',
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
});

const labelStyle = css({
	color: token('color.text.subtlest'),
	fontSize: token('font.body.small'),
});

const flyoutWrapper = xcss({
	minWidth: '360px',
	maxWidth: '512px',
});
