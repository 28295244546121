import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import AKAvatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { VARIATION } from '@atlassian/jira-common-util-get-experiment-group';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { type IntlShape, injectIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRealtimePresenceExperiment } from '@atlassian/jira-real-time-presence/src/controllers/use-realtime-presence-experiment/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import type { Person } from '../../../../../common/types';
import {
	AvatarBorder,
	AvatarWrapper,
	hiddenCheckboxStyles,
} from '../../../../../common/ui/assignee';
import { onBlur, useOnKeyPress } from '../../../../../common/ui/assignee/utils';
import PresenceTooltip from '../../../../../common/ui/presence-tooltip';
import messages from './messages';

export type Props = {
	isSelected?: boolean;
	user: Person;
	zIndex: number;
	onToggle: (userId: AccountId) => void;
	intl: IntlShape;
};

const type = 'assignee';

const AvatarWithAnalytics = ({
	isRealtimePresenceEnabled,
	intl,
	...user
}: Person & { isRealtimePresenceEnabled: boolean; intl: IntlShape }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = intl;

	const onShow = () => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'tooltip viewed');
	};

	const altTextWithPresence = formatMessage(
		user.presence === 'online' ? messages.presenceOnlineLabel : messages.presenceOfflineLabel,
		{ name: user.displayName },
	);

	if (isRealtimePresenceEnabled)
		return (
			<PresenceTooltip
				content={user.label || user.displayName}
				onShow={onShow}
				tag="span"
				presence={user.presence}
			>
				<AKAvatar
					testId="filters.ui.filters.assignee.stateless.avatar.ak-avatar"
					size={isVisualRefreshEnabled() ? 'small' : 'medium'}
					as="span"
					src={user.avatarUrl}
					name={user.presence ? altTextWithPresence : user.displayName}
					presence={user.presence}
				/>
			</PresenceTooltip>
		);

	return (
		<Tooltip content={user.label || user.displayName} onShow={onShow} tag="span">
			<AKAvatar
				testId="filters.ui.filters.assignee.stateless.avatar.ak-avatar"
				size={isVisualRefreshEnabled() ? 'small' : 'medium'}
				as="span"
				src={user.avatarUrl}
				name={user.displayName}
				presence={user.presence}
			/>
		</Tooltip>
	);
};

const Avatar = (props: Props) => {
	const { user, isSelected, zIndex, intl } = props;

	const { formatMessage } = intl;

	const userIdentifier = user.id;

	const checkboxId = `${type}-${userIdentifier}`;

	const onToggle = useCallback(() => {
		props.onToggle(user.id);
	}, [props, user.id]);

	const onKeyPress = useOnKeyPress(onToggle);

	const { experimentCohort } = useRealtimePresenceExperiment();

	const isRealtimePresenceEnabled = experimentCohort === VARIATION;

	const altTextWithPresence = formatMessage(
		user.presence === 'online' ? messages.presenceOnlineLabel : messages.presenceOfflineLabel,
		{ name: user.displayName },
	);

	const avatarWithTooltip = isRealtimePresenceEnabled ? (
		<PresenceTooltip content={user.displayName} presence={user.presence} tag="span">
			<AKAvatar
				testId="filters.ui.filters.assignee.stateless.avatar.ak-avatar"
				size={isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? 'small' : 'medium'}
				as="span"
				src={user.avatarUrl}
				name={user.presence ? altTextWithPresence : user.displayName}
				presence={user.presence}
			/>
		</PresenceTooltip>
	) : (
		<Tooltip content={user.displayName} tag="span">
			<AKAvatar
				testId="filters.ui.filters.assignee.stateless.avatar.ak-avatar"
				size={isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? 'small' : 'medium'}
				as="span"
				src={user.avatarUrl}
				name={user.displayName}
				presence={user.presence}
			/>
		</Tooltip>
	);

	return (
		<AvatarWrapper selected={isSelected} zIndex={zIndex}>
			<AvatarInputCheckbox
				aria-label={formatMessage(messages.avatarInputCheckboxLabel)}
				type="checkbox"
				id={checkboxId}
				name={type}
				value={userIdentifier}
				defaultChecked={isSelected}
				onChange={onToggle}
				onKeyPress={onKeyPress}
				onClick={onBlur}
			/>
			<AvatarBorder selected={isSelected}>
				<AvatarLabel
					data-test-id="filters.ui.filters.assignee.stateless.avatar.assignee-filter-avatar"
					data-testid="filters.ui.filters.assignee.stateless.avatar.assignee-filter-avatar"
					htmlFor={checkboxId}
				>
					{ff('jsw.enable.avatar.tooltip.label.and.analytic_hikl3') ? (
						<AvatarWithAnalytics
							{...user}
							isRealtimePresenceEnabled={isRealtimePresenceEnabled}
							intl={intl}
						/>
					) : (
						avatarWithTooltip
					)}
				</AvatarLabel>
			</AvatarBorder>
		</AvatarWrapper>
	);
};

Avatar.defaultProps = {
	isSelected: false,
	onToggle: noop,
};

export default injectIntl(Avatar);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarLabel = styled.label({
	lineHeight: 0,
	display: 'block',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const AvatarInputCheckbox = styled.input(hiddenCheckboxStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus + div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.text.selected', colors.B100),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	AvatarInputCheckbox,
};
