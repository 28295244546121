import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	none: {
		id: 'software.main.model.swimlane.none',
		defaultMessage: 'None',
		description: 'An option in a dropdown to group by none',
	},
	assignee: {
		id: 'software.main.model.swimlane.assignee',
		defaultMessage: 'Assignee',
		description: 'An option in a dropdown to group by assignee',
	},
	assigneeunassignedfirst: {
		id: 'software.main.model.swimlane.assigneeunassignedfirst',
		defaultMessage: 'Assignee with unassigned issues showing above',
		description:
			'An option in a dropdown to group by assignee with unassigned issues showing above other swimlane',
	},
	epic: {
		id: 'software.main.model.swimlane.epic',
		defaultMessage: '{displayText}',
		description: 'An option in a dropdown to group by epic',
	},
	subtask: {
		id: 'software.main.model.swimlane.subtask',
		defaultMessage: '{displayText}',
		description: 'An option in a dropdown to group by subtask',
	},
	stories: {
		id: 'software.main.model.swimlane.stories',
		defaultMessage: 'Stories',
		description: 'An option in dropdown to group by stories',
	},
	unassigned: {
		id: 'software.main.model.swimlane.unassigned',
		defaultMessage: 'Unassigned',
		description: 'Label in a swimlane categorising issues which are unassigned',
	},
	parentless: {
		id: 'software.main.model.swimlane.parentless',
		defaultMessage: 'Issues without {issueParentTypeName}',
		description:
			'Label in a swimlane categorising issues which do not have a parent. {issueParentTypeName} refers to the name given to the parent issue type',
	},
	jql: {
		id: 'software.main.model.swimlane.jql',
		defaultMessage: 'Queries',
		description: 'Label in a swimlane categorising issues that match custom JQL queries.',
	},
	project: {
		id: 'software.main.model.swimlane.project',
		defaultMessage: 'Projects',
		description: 'An option in a dropdown to group by project',
	},
	childless: {
		id: 'software.main.model.swimlane.childless',
		defaultMessage: 'Everything else',
		description:
			'Label in a swimlane categorising issues which do not have subtasks and visible parent',
	},
	requestType: {
		id: 'software.main.model.swimlane.request-type',
		defaultMessage: 'Request type',
		description: 'An option in a dropdown to group by request type',
	},
	noRequestType: {
		id: 'software.main.model.swimlane.no-request-type',
		defaultMessage: 'No request type',
		description: 'Group tickets that has no request type',
	},
	assigneeunassignedfirstIssueTermRefresh: {
		id: 'software.main.model.swimlane.assigneeunassignedfirst-issue-term-refresh',
		defaultMessage: 'Assignee with unassigned issues showing above',
		description:
			'An option in a dropdown to group by assignee with unassigned issues showing above other swimlane',
	},
	parentlessIssueTermRefresh: {
		id: 'software.main.model.swimlane.parentless-issue-term-refresh',
		defaultMessage: 'Issues without {issueParentTypeName}',
		description:
			'Label in a swimlane categorising issues which do not have a parent. {issueParentTypeName} refers to the name given to the parent issue type',
	},
});
