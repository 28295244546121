import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	doneTickTooltip: {
		id: 'platform-board-kit.common.column-header.editable-title.done-column-tick.done-tick-tooltip',
		defaultMessage: 'Issues moved to this column are marked as done',
		description:
			'Done tick icon. Shows that the column is a done column. Special visual treatment for the done column in the header.',
	},
	doneTickTooltipIssueTermRefresh: {
		id: 'platform-board-kit.common.column-header.editable-title.done-column-tick.done-tick-tooltip-issue-term-refresh',
		defaultMessage: 'Issues moved to this column are marked as done',
		description:
			'Done tick icon. Shows that the column is a done column. Special visual treatment for the done column in the header.',
	},
});
