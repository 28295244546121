import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	textFilter: {
		id: 'software-filters.stateless.text-filter',
		defaultMessage: 'Filter issues',
		description: 'The placeholder of a input textbox used to enter text to filter issues by.',
	},
	screenReaderInstruction: {
		id: 'software-filters.stateless.screen-reader-instruction',
		defaultMessage: 'Results will be filtered below as you type to search or apply filters.',
		description: 'Describes what the issue filters do for screen reader users.',
	},
	screenReaderInstructionForFilters: {
		id: 'software-filters.stateless.screen-reader-instruction-for-filters',
		defaultMessage: 'Content below has been updated',
		description: 'Describes what the issue filters do for screen reader users.',
	},
	searchCurrentPage: {
		id: 'software-filters.stateless.search-current-page',
		defaultMessage: 'Search on current page',
		description: 'Command in the command palette to trigger the page search',
	},
	goToSection: {
		id: 'software-filters.stateless.go-to-section',
		defaultMessage: 'Go to',
		description:
			'Command Palette section name, used to group navigation commands like backlog, board, page search',
	},
	unassignedAssigneeDisplayName: {
		id: 'software-filters.stateless.unassigned-assignee-display-name',
		defaultMessage: 'Unassigned',
		description: 'The display name for the avatar in the assignee filter for all unassigned issues',
	},
	unassignedVersionLabel: {
		id: 'software-filters.stateless.unassigned-version-label',
		defaultMessage: 'Issues without version',
		description:
			'The label for the option in the version filter to select issues that do not have any versions assigned',
	},
	unassignedLabelLabel: {
		id: 'software-filters.stateless.unassigned-label-label',
		defaultMessage: 'Issues without label',
		description:
			'The label for the option in the label filter to select issues that do not have any labels assigned',
	},
	unassignedComponentLabel: {
		id: 'software-filters.stateless.unassigned-component-label',
		defaultMessage: 'Issues without component',
		description:
			'The label for the option in the component filter to select issues that do not have any components assigned',
	},
	textFilterIssueTermRefresh: {
		id: 'software-filters.stateless.text-filter-issue-term-refresh',
		defaultMessage: 'Filter issues',
		description: 'The placeholder of a input textbox used to enter text to filter issues by.',
	},
	unassignedVersionLabelIssueTermRefresh: {
		id: 'software-filters.stateless.unassigned-version-label-issue-term-refresh',
		defaultMessage: 'Issues without version',
		description:
			'The label for the option in the version filter to select issues that do not have any versions assigned',
	},
	unassignedLabelLabelIssueTermRefresh: {
		id: 'software-filters.stateless.unassigned-label-label-issue-term-refresh',
		defaultMessage: 'Issues without label',
		description:
			'The label for the option in the label filter to select issues that do not have any labels assigned',
	},
	unassignedComponentLabelIssueTermRefresh: {
		id: 'software-filters.stateless.unassigned-component-label-issue-term-refresh',
		defaultMessage: 'Issues without component',
		description:
			'The label for the option in the component filter to select issues that do not have any components assigned',
	},
});
