import React, { type MouseEvent } from 'react';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { VISIBLE } from '../../common/constants';
import type { TriggerAppearance } from '../../common/types';
import HiddenTrigger from './hidden/main.tsx';
import messages from './messages';
import VisibleTrigger from './visible/main.tsx';

function Trigger({
	isDisabled,
	triggerAppearance,
	isSticky,
	label,
	ariaLabelledBy,
	onClick,
	intl: { formatMessage },
	isModalTrigger,
	shouldFocusTrigger = false,
}: {
	isDisabled?: boolean;
	isSticky: boolean;
	triggerAppearance: TriggerAppearance;
	label: string;
	ariaLabelledBy?: string;
	onClick: (arg1: MouseEvent<HTMLElement>) => void;
	intl: IntlShape;
	isModalTrigger?: boolean;
	shouldFocusTrigger?: boolean;
}) {
	const defaultLabel =
		label ||
		formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.labelIssueTermRefresh
				: messages.label,
		);

	if (triggerAppearance === VISIBLE) {
		return (
			<VisibleTrigger
				isModalTrigger={isModalTrigger}
				isSticky={isSticky}
				label={defaultLabel}
				ariaLabelledBy={ariaLabelledBy}
				onClick={onClick}
				isDisabled={isDisabled}
				{...(fg('jsw_roadmaps_timeline-issue-creation-ally') ? { shouldFocusTrigger } : {})}
			/>
		);
	}

	if (isDisabled !== true) {
		return (
			<HiddenTrigger
				label={defaultLabel}
				onClick={onClick}
				{...(fg('jsw_roadmaps_timeline-issue-creation-ally') ? { shouldFocusTrigger } : {})}
			/>
		);
	}
	return null;
}

Trigger.defaultProps = {
	isDisabled: false,
	isSticky: false,
	triggerAppearance: VISIBLE,
	label: '',
	onClick: noop,
	shouldFocusTrigger: false,
};

export { Trigger };

export default AnalyticsEventToProps('button', {
	onClick: 'clicked',
})(injectIntl(Trigger));
