import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeboardingMessage: {
		id: 'software-filters.search-filter-changeboarding.changeboarding-message',
		defaultMessage:
			'For quick access to search, use {key} on your keyboard anywhere in Jira. It takes you directly to the search field.',
		description:
			'The message presented to the user when they use control+f/cmd+f to use the global browser search',
	},
	changeboardingUifMessageHeading: {
		id: 'software-filters.search-filter-changeboarding.changeboarding-uif-message-heading',
		defaultMessage: 'Search for an issue',
		description:
			'The heading presented to the user when they use control+f/cmd+f to use the global browser search on UIF',
	},
	changeboardingUifMessage: {
		id: 'software-filters.search-filter-changeboarding.changeboarding-uif-message',
		defaultMessage:
			'The backlog loads issues as you scroll to help increase performance. If you struggle to find results using a keyboard shortcut, try entering the keyword here instead.',
		description:
			'The message presented to the user when they use control+f/cmd+f to use the global browser search on UIF',
	},
	hijackingDefaultSearchMessage: {
		id: 'software-filters.search-filter-changeboarding.hijacking-default-search-message',
		defaultMessage:
			'{ ctrlKey } + { key } takes you directly to the search field, just like keyboard shortcut { key }. Both shortcuts give you quick access to our optimized search.',
		description:
			'The message presented to the user when they press control+f/cmd+f and is directed to search field filter bar',
	},
	changeboardingConfirmButton: {
		id: 'software-filters.search-filter-changeboarding.changeboarding-confirm-button',
		defaultMessage: 'OK',
		description: 'Text for the button that dismisses the changeboarding message',
	},
	changeboardingUifMessageHeadingIssueTermRefresh: {
		id: 'software-filters.search-filter-changeboarding.changeboarding-uif-message-heading-issue-term-refresh',
		defaultMessage: 'Search for an issue',
		description:
			'The heading presented to the user when they use control+f/cmd+f to use the global browser search on UIF',
	},
	changeboardingUifMessageIssueTermRefresh: {
		id: 'software-filters.search-filter-changeboarding.changeboarding-uif-message-issue-term-refresh',
		defaultMessage:
			'The backlog loads issues as you scroll to help increase performance. If you struggle to find results using a keyboard shortcut, try entering the keyword here instead.',
		description:
			'The message presented to the user when they use control+f/cmd+f to use the global browser search on UIF',
	},
});
