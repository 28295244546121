import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	prompt: {
		id: 'inline-card-create.form.summary.prompt',
		defaultMessage: 'What needs to be done?',
		description: 'Placeholder text in the summary field to prompt the user to enter a summary.',
	},
	maxLength: {
		id: 'inline-card-create.form.summary.max-length',
		defaultMessage: 'The issue summary must be less than or equal to 255 characters.',
		description: 'Warning in an inline message that explains why the user can not create an issue.',
	},
	maxLengthMultiple: {
		id: 'inline-card-create.form.summary.max-length-multiple',
		defaultMessage: 'The summary of every issue must be less than or equal to 255 characters.',
		description:
			'Tooltip warning on a Create button that explains why the user can not create multiple issues.',
	},
	issueWithNameCreatedSuccessfully: {
		id: 'inline-card-create.form.summary.issue-with-name-created-successfully',
		defaultMessage: 'Issue {name} created successfully',
		description: 'Message for screen-reader while issue created',
	},
	ariaLabel: {
		id: 'inline-card-create.form.summary.aria-label',
		defaultMessage:
			'Add your issue summary and then press Enter to submit. Use the Escape key to cancel.',
		description: 'Message for screen-reader when the field is in focus',
	},
	ariaLabelSelectedType: {
		id: 'inline-card-create.form.summary.aria-label-selected-type',
		defaultMessage:
			'{value} selected. Add your issue summary and then press Enter to submit. Use the Escape key to cancel.',
		description:
			'Message for screen-reader when the input field is in focus to read out selected issue type and instructions.',
	},
	ariaLabelOld: {
		id: 'inline-card-create.form.summary.aria-label-old',
		defaultMessage:
			'What needs to be done? - Press the "Enter" key to submit or the "Escape" key to cancel.',
		description: 'Message for screen-reader when the field is in focus',
	},
	maxLengthIssueTermRefresh: {
		id: 'inline-card-create.form.summary.max-length-issue-term-refresh',
		defaultMessage: 'The issue summary must be less than or equal to 255 characters.',
		description: 'Warning in an inline message that explains why the user can not create an issue.',
	},
	maxLengthMultipleIssueTermRefresh: {
		id: 'inline-card-create.form.summary.max-length-multiple-issue-term-refresh',
		defaultMessage: 'The summary of every issue must be less than or equal to 255 characters.',
		description:
			'Tooltip warning on a Create button that explains why the user can not create multiple issues.',
	},
	issueWithNameCreatedSuccessfullyIssueTermRefresh: {
		id: 'inline-card-create.form.summary.issue-with-name-created-successfully-issue-term-refresh',
		defaultMessage: 'Issue {name} created successfully',
		description: 'Message for screen-reader while issue created',
	},
	ariaLabelIssueTermRefresh: {
		id: 'inline-card-create.form.summary.aria-label-issue-term-refresh',
		defaultMessage:
			'Add your issue summary and then press Enter to submit. Use the Escape key to cancel.',
		description: 'Message for screen-reader when the field is in focus',
	},
	ariaLabelSelectedTypeIssueTermRefresh: {
		id: 'inline-card-create.form.summary.aria-label-selected-type-issue-term-refresh',
		defaultMessage:
			'{value} selected. Add your issue summary and then press Enter to submit. Use the Escape key to cancel.',
		description:
			'Message for screen-reader when the input field is in focus to read out selected issue type and instructions.',
	},
});
